import React, { Component } from "react";
import { InputGroup, Form } from "react-bootstrap";

import "./../css/widget/FormCard.css";
class FormRadioGroup extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  render() {
    return (
      <div className="input-field">
        <Form.Group className="mb-3" controlId={this.state.id}>
          <Form.Label>{this.state.title}</Form.Label>
          <InputGroup hasValidation>
            {this.state.options.map((option, index) => (
              <Form.Check
                key={index}
                id={this.state.id+""+option.value}
                type="radio"
                label={option.label}
                name={this.state.name}
                value={option.value}
                checked={this.state.value === option.value}
                onChange={this.state.handle}  
                isInvalid={this.state.error?true:false}
                feedback={this.state.error}
              />
            ))}
            <Form.Control.Feedback type="invalid">
              {this.state.error}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </div>
    );
  }
}
export default FormRadioGroup;
